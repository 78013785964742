import logo from './HKCH IT Logo.png'
import styles from './ProductList.module.css'
import {useState, useEffect} from "react" //React Hook  16.8
import {Link} from "react-router-dom"
import Title from './Title'
import QtyBan from './QtyBan'
import React from 'react'

export default function ProductList() {

    /* let productList = [ */
     /*    {"id":1, "name": "apple", "price": 5, "image" : "apple.jpg", "desc" : "fresh apple, dr leave you"}, */
     /*    {"id":2, "name": "orange", "price": 8, "image" : "orange.jpg", "desc" : "fresh orange, vit C more"}, */
     /*    {"id":3, "name": "mange", "price": 13, "image" : "mango.jpg", "desc" : "fresh mango, so sweet"} */
    /*]  */

    
    let [productList, setProductList] = useState([])
    let [input, setInput] = useState('')


    useEffect(()=>{
        //1. component every render
        //2. only once 
        //3. 
        console.log(productList)
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => setProductList(data))

            
        console.log(productList)
    },[]) //<= dependency array

    useEffect(()=>{
        console.log(input)
    },[input])

    //let product = 'fruit'
    const [product, setProduct] = useState('fruit')

    const [showProduct, setShowProduct] = useState(false)

    const handleClick = ()=>{
       setProduct('react')
       console.log(product)
    }


    return (
        //React Fragment
        <>
            <input type="text" onChange={e=>setInput(e.target.value)}/>
            <button onClick={()=>setProductList('change')}>Change product list value</button>
            <Title mainTitle="Pls choose your fruit" subTitle="Today Big Deal"/>

            {product} <button onClick={handleClick}>Change value</button>
            {showProduct && <button onClick={()=>{setShowProduct(false)}}>Hide</button>}
            {!showProduct && <button onClick={()=>{setShowProduct(true)}}>Show</button>} 

         

            <img src={logo} width='100'/>
            <div>
                {
                    //process.env.PUBLIC_URL
                    showProduct && productList.map(product=>{
                        return(
                            <div className={styles.productBroder} key={product.id}>
                                {product.name}<br/>
                                {product.price}<br/>
                                <Link to={'/product/' + product.id}>
                               <img src={process.env.PUBLIC_URL + '/img/' + product.image} /><br/>
                               </Link>
                                {product.description}<br/>
                                <QtyBan productInfo={product}/>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}