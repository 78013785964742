import Title from "./Title.js"
import {Link} from 'react-router-dom'
import QtyBan from './QtyBan'
import { useContext } from "react"
import { CartContext } from "./CartContext.js"


export default function Checkout() {
    let {cartItems }= useContext(CartContext)
    // {
    //     "cartItems":
    //     [
    //         {
    //             "id":1,
    //             "name":"apple",
    //             "price":5,
    //             "qty":4
    //         },
    //         {
    //             "id":3,
    //             "name":"mango",
    //             "price":5,
    //             "qty":4
    //         }
    //     ],
    //     "userInfo":
    //     [
    //         {
    //             "name2":''
    //         }
    //     ]
    // }
    //let {cartItems} = cartItem
    let cartEmpty = cartItems.length <= 0 ? true : false

    let grantTotal = cartItems.reduce((total, product)=>{
        return total += product.price*product.qty
    },0)

    const freeShippingPrice = 99

    return (
        <div>
            <Title mainTitle = 'Your cart'/>

            {
                cartEmpty &&
                <div>
                    no stock<br/>
                    <Link to="/">Go Product</Link>
                </div>
            }
            {
                !cartEmpty &&
                <div>
                   <div id="cartSection">   
                        {
                            cartItems.map((product)=>(
                                <div key={product.id}>
                                    {product.name}&nbsp;
                                    {product.price}&nbsp;
                                    {product.qty}&nbsp;
                                    <QtyBan productInfo={product}/>
                                </div>
                            ))
                        }
                   </div>
                   <div id="checkOutSection">
                        <div>Total</div>
                        <div>Price {grantTotal}</div>
                        {
                            grantTotal >= freeShippingPrice?
                            <div>fee shipping</div> :
                            <div>over {freeShippingPrice} with free</div>

                        }
                    </div> 
                </div>
            }
        </div>
    )
}