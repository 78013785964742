import Title from "./Title.js"
import {useParams, Link} from "react-router-dom"
import QtyBan from './QtyBan'
import { useState, useEffect } from "react"


export default function ProductDetail() {
    
    let params = useParams()
    let [productDetail,setProductDetail] = useState(null)

    useEffect(()=>{
        //1. component every render
        //2. only once 
        //3. 
        //console.log(productList)
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            // .then(data => setProductList(data))
            .then(data => {
                let productInfo = data.find((element)=>{
                    return element.id === parseInt(params.id)
                })
                setProductDetail(productInfo)
            })
            
        //console.log(productList)
    },[]) //<= dependency array

    return (
        <div>
            {
                productDetail &&
                <div>
                    <Title mainTitle={productDetail.name     + 'Product Info'}/>
                    <p>{productDetail.name}</p>
                    <p>{productDetail.description}</p>
                    <QtyBan productInfo={productDetail}/>
                </div>
            }
        
            <Link to="/">Back</Link>
        </div>
    )
}


