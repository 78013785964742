import {BrowserRouter,Routes, Route, Link} from 'react-router-dom';
import Checkout from './Checkout';
import ProductDetail from './ProductDetail';
import ProductList from './ProductList'
import {CartContext} from './CartContext'
import {useState} from 'react';

function App() {
  const [cartItems, setCartItems] = useState([])


  return (
    <div>
      <BrowserRouter>

        <CartContext.Provider value ={{cartItems,setCartItems}}>

          <a href="/checkout">Shopping Cart (a tag)</a>&nbsp;

          <Link to="/">Home</Link>&nbsp;
          <Link to="/product">Product Info</Link>&nbsp;
          <Link to="/checkout">Shopping Cart</Link>&nbsp;
          <Routes>
            <Route path="/" element={<ProductList/>} />
            <Route path="/checkout" element={<Checkout/>} />
            <Route path="/product" element={<ProductDetail/>}>
              <Route path=":id" element={<ProductDetail/>}/>

            </Route>
            <Route path="*" element={<p>no</p>}/>
          </Routes>
        </CartContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
